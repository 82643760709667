import React from 'react'
import styled from 'styled-components'
import ImageBuilder from '../../elements/imageBuilder'

import TitleTextButtonCard from '../../compositions/titleTextButton'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  .imageContainer {
    order: ${(props) => (props.variant == 'left' ? 2 : 1)};
    flex: 0.74;
    span {
      width: 100% !important;
      max-height: 630px;
      img {
        max-height: 630px !important;
        width: inherit !important;
        object-fit: cover;
        object-position: center;
      }
    }
    iframe {
      width: 100% !important;
      max-height: 630px;
      height: 630px;
    }
  }
  .content {
    ${(props) =>
      props.variant == 'left'
        ? `
            order: 1;
            margin-right: -23%;
        `
        : `
            order: 2;
            margin-left: -23%;
        `}
    flex: 0.49;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    box-shadow: 0px 17px 98px rgba(0, 0, 0, 0.05),
      0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054),
      0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946);
    z-index: 10;
    @media screen and (max-width: 1200px) {
      flex: 0.6;
    }

    @media screen and (max-width: 1000px) {
      margin: 0;
    }
  }

  @media screen and (max-width: 1000px) {
    display: block;

    .imageContainer,
    .content {
      display: block;
    }
  }
`

const MediaTextOverlapBlock = ({
  title,
  text,
  button,
  image,
  variant,
  embed,
}) => {
  return (
    <Wrapper variant={variant}>
      <div className="imageContainer">
        {image && (
          <ImageBuilder
            decorativeOnly={true}
            image={image}
            height={630}
            width={987}
          />
        )}
        {!image && embed && (
          <iframe
            src={embed}
            title={embed}
            height={630}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>
      <div className="content">
        <TitleTextButtonCard
          title={title}
          text={text}
          button={button}
          variant="overlap"
        />
      </div>
    </Wrapper>
  )
}

export default MediaTextOverlapBlock
