import React from 'react'
import styled from 'styled-components'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

import ButtonElement from '../../elements/buttons'

const TabsWrapper = styled.div`
  .informationBox {
    background: #f4f6f8;
    min-height: 84px;
    padding: 20px;
    position: relative;
  }

  .swiper-slide {
    display: inline-block;
    width: auto !important;
  }

  .buttonElement {
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 60px;
    display: inline-block;
    cursor: pointer;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding: 11px 22px;

    &.active {
      background: #1f486a;
      border-radius: 48px;
      color: #fff;
      border: 1px solid #1f486a;
    }
  }

  .tabSwiper {
    .swiper-button-next,
    .swiper-button-prev {
      background: linear-gradient(
        90deg,
        rgba(244, 246, 248, 0) 0%,
        rgba(244, 246, 248, 1) 50%,
        rgba(244, 246, 248, 1) 100%
      );
      color: black;
      height: 110%;
      opacity: 1;
      top: 45%;
      width: 50px;
      ::after {
        width: 25px;
        height: 25px;
        font-size: 25px;
        font-weight: 800;
      }
    }
    .swiper-button-next {
      background: linear-gradient(
        90deg,
        rgba(244, 246, 248, 0) 0%,
        rgba(244, 246, 248, 1) 50%,
        rgba(244, 246, 248, 1) 100%
      );
      padding-left: 40px;
      right: 0;
      ::after {
        margin-right: 5px;
      }
    }
    .swiper-button-prev {
      background: linear-gradient(
        270deg,
        rgba(244, 246, 248, 0) 0%,
        rgba(244, 246, 248, 1) 50%,
        rgba(244, 246, 248, 1) 100%
      );
      padding-right: 40px;
      left: 0;
      ::after {
        margin-left: 5px;
      }
    }
    .swiper-button-disabled {
      display: none;
    }
  }

  .arrowRight {
    width: 25px;
    height: 25px;
    cursor: pointer;
    background: linear-gradient(
      90deg,
      rgba(244, 246, 248, 0) 0%,
      rgba(244, 246, 248, 1) 60%,
      rgba(244, 246, 248, 1) 100%
    );
    position: absolute;
    z-index: 9999;
    top: 28px;
    right: 30px;

    img {
      position: relative;
      top: 36px;
      right: -110px;
    }
  }
  .swiperButton {
    padding: 0;
  }
`

const Tabs = ({ buttons, handleClick, activeId = 0 }) => {
  return (
    <TabsWrapper>
      <div className="informationBox">
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={15}
          navigation={true}
          modules={[Navigation]}
          className="tabSwiper"
          initialSlide={activeId}
        >
          {buttons &&
            buttons?.map((item) => (
              <SwiperSlide key={item.title}>
                <ButtonElement
                  variant="empty"
                  handleClick={() => {
                    handleClick(item)
                  }}
                  className="swiperButton"
                >
                  <div
                    className={`buttonElement ${
                      activeId === item.id ? 'active' : ''
                    }`}
                  >
                    {item.title}
                  </div>
                </ButtonElement>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </TabsWrapper>
  )
}
export default Tabs
