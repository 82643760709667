import React from 'react'
import styled from 'styled-components'

import InformationTabs from '../../modules/informationTabs'
import EventResultsList from '../../modules/eventResults'

import WidthContainer from '../../elements/widthContainer'
import { grey_background } from '../../colors'

import { searchEventsApiClient } from '../../utils/api/fetchApi'
import { getMomentTz } from '../../utils/getMomentTz'

const Wrapper = styled.div`
  .tabContainer {
    background-color: ${grey_background};
  }
  padding-bottom: 106px;

  .results {
    min-height: 1000px;
  }
`

const ITEMS_PER_PAGE = 12

const UpcomingEvents = ({
  perPage = ITEMS_PER_PAGE,
  filters,
  host,
  eventCategories,
}) => {
  const [page, setPage] = React.useState(1)

  const [active, setActive] = React.useState(0)
  const [events, setEvents] = React.useState([])
  const [category, setCategory] = React.useState(null)
  const [sportCategory, setSportCategory] = React.useState()
  const [sportfilter, setSportfilter] = React.useState()
  const [minDate, setMinDate] = React.useState(
    getMomentTz().startOf('day').format()
  )
  const [maxDate, setMaxDate] = React.useState()

  const [count, setCount] = React.useState(0)
  const [pages, setPages] = React.useState(1)

  React.useEffect(() => {
    getEventData({
      pageInput: page,
      sportCategoryInput: sportCategory,
      categoryInput: category,
      sportsInput: sportfilter,
      perPage: perPage,
      minDateInput: minDate,
      maxDateInput: null,
    })
  }, [])

  const getEventData = async ({
    pageInput = null,
    sportCategoryInput = null,
    categoryInput = null,
    sportsInput = null,
    perPage = ITEMS_PER_PAGE,
    minDateInput = null,
    maxDateInput = null,
  }) => {
    const mapSports =
      (sportsInput || sportfilter)?.map((item) => item.slug) || []
    const query = {
      page: pageInput || page,
      category: categoryInput,
      sports: mapSports,
      sport_category: (sportCategoryInput || sportCategory)?.slug,
      per_page: perPage,
      start_date: minDateInput || minDate,
      end_date: maxDateInput || maxDate,
    }
    const data = await searchEventsApiClient(host, query)
    const { search_results } = data || {}

    setEvents(search_results?.pages)
    setCount(search_results?.count)
    setPages(search_results?.num_pages || 1)
  }

  const handleTab = (tab) => {
    // Handles event category change
    setActive(tab.id)
    if (tab.id == 0) {
      setCategory('')
      getEventData({
        categoryInput: '',
      })
    } else {
      setCategory(tab.slug)
      getEventData({
        categoryInput: tab.slug,
      })
    }
  }

  const handleDateChange = (date) => {
    // Handles change date. Formats dates for backend processing
    const { startDate, endDate } = date
    const formattedStart = getMomentTz(startDate).startOf('day').format()
    const formattedEnd = getMomentTz(endDate).endOf('day').format()
    setMinDate(formattedStart)
    setMaxDate(formattedEnd)
    getEventData({
      minDateInput: formattedStart,
      maxDateInput: formattedEnd,
    })
  }

  const handleFilterChange = (sport) => {
    // Handles changes in sports filters
    let newState = sportfilter && sportfilter.length > 0 ? [...sportfilter] : []
    if (newState.includes(sport)) {
      newState = newState.filter((item) => item.id != sport.id)
    } else {
      newState.push(sport)
    }
    setSportfilter(newState)
    getEventData({
      sportsInput: newState,
    })
  }
  const handleCategorySelection = (input) => {
    // Handles selection of sport category.
    // Set sports to empty list when a new category is selected
    if (input.id == sportCategory?.id) {
      // Deselect category
      setSportCategory(null)
      setSportfilter([])
      getEventData({
        sportsInput: [],
        sportCategoryInput: {},
      })
      return
    }
    setSportCategory(input)
    setSportfilter([])
    getEventData({
      sportsInput: [],
      sportCategoryInput: input,
    })
  }

  const handlePageChange = (e, newPage) => {
    e.preventDefault()
    if (newPage > 0 && newPage <= pages) {
      // Only update for appplicable pages
      setPage(newPage)
      getEventData({
        pageInput: newPage,
      })
    }
  }

  return (
    <Wrapper>
      <div className="tabContainer">
        <WidthContainer variant="full">
          <InformationTabs
            buttons={eventCategories}
            activeId={active}
            handleClick={handleTab}
          />
        </WidthContainer>
      </div>
      <div className="results">
        <WidthContainer variant="full">
          <EventResultsList
            events={events || []}
            totalResults={count || 0}
            filters={filters}
            handleDateChange={handleDateChange}
            handleFilterChange={handleFilterChange}
            handleCategorySelection={handleCategorySelection}
            selectedSports={sportfilter}
            selectedCategory={sportCategory}
            page={page}
            pages={pages}
            handlePageChange={handlePageChange}
          />
        </WidthContainer>
      </div>
    </Wrapper>
  )
}

export default UpcomingEvents
